import { isNil } from "lodash";
import { nextTick, onMounted, ref, reactive, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
  name: "BaseModal",
  inheritAttrs: false,
  props: {
    modelValue: {},
    value: {
      type: Boolean
    },
    persistant: {
      type: Boolean
    },
    closable: {
      type: Boolean
    },
    width: {
      type: Number
    },
    height: {
      type: [Number, String]
    },
    minHeight: {
      type: [Number, String]
    },
    zIndex: {
      type: Number
    },
    reloadOnClose: {
      type: Boolean,
      default: false
    },
    routeOnClose: {
      type: [String, Object],
      default: null
    },
    // New optional prop to enable content based width
    adaptiveWidth: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue", "close-modal"],
  setup(props, {
    emit
  }) {
    const done = ref(false);
    const flag = ref(true);
    const screenInnerHeight = ref(window.innerHeight - 100);
    const screenInnerWidth = ref(window.innerWidth);
    const heightAdjustment = reactive({
      heightValue: props.height,
      screenHeight: screenInnerHeight
    });
    const maxHeight = computed(() => {
      return (heightAdjustment.heightValue * 1 > heightAdjustment.screenHeight ? heightAdjustment.screenHeight : heightAdjustment.heightValue * 1) ?? heightAdjustment.screenHeight;
    });
    const modalInner = ref(null);
    const router = useRouter();
    const route = useRoute();
    const handleClose = () => {
      emit("update:modelValue", false);
      flag.value = false;
      emit("close-modal", false);
      if (props.routeOnClose === "route") {
        router.replace({
          path: route.path
        });
        return;
      }
      if (props.routeOnClose) {
        router.push(props.routeOnClose);
        return;
      }
      if (props.reloadOnClose) {
        if (props.routeOnClose) {
          router.push(props.routeOnClose); // Navigate to the specified route
          window.location.reload();
        } else {
          window.location.reload(); // Reload the current page
        }
        window.location.reload();
      }
    };

    // Computed style for modal inner
    const modalComputedStyle = computed(() => {
      if (props.adaptiveWidth) {
        // When adaptiveWidth is true, let the content define the width
        return {
          width: "auto",
          maxWidth: "none"
        };
      } else {
        // Otherwise, use the provided width prop (and clamp it to the screen width)
        const computedWidth = (props.width * 1 > screenInnerWidth.value ? screenInnerWidth.value : props.width * 1) || screenInnerWidth.value;
        return {
          maxWidth: computedWidth + "px"
        };
      }
    });
    done.value = true;
    onMounted(async () => {
      await nextTick();
      modalInner.value?.addEventListener("mousedown", e => {
        if (e.target === e.currentTarget && !props.persistant) {
          handleClose();
        }
      });
    });
    return {
      done,
      flag,
      screenInnerHeight,
      screenInnerWidth,
      modalInner,
      isNil,
      maxHeight,
      handleClose,
      modalComputedStyle
    };
  }
};